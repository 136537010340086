<template>
  <section id="orders">
    <b-card-actions
      title="Filters"
      action-collapse
    >
      <b-form-group
        label="Selected Hubs"
      >
        <v-select
          multiple
          v-model="selectedHubs"
          label="name"
          :options="hubs"
          @input="changeSelectedHubs"
        />
      </b-form-group>
      <b-form-group
        label="Search"
      >
        <b-form-input
          class="d-inline-block mr-1"
          placeholder="Search..."
          v-model="searchInputValue"
          @keyup.enter="searchOrder"
        />
      </b-form-group>
    </b-card-actions>
    <b-card>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :current-page="ordersTable.currentPage"
        :items="ordersTable.items"
        :fields="ordersTable.fields"
        :sort-by.sync="ordersTable.sortBy"
        :sort-desc.sync="ordersTable.sortDesc"
        :sort-direction="ordersTable.sortDirection"
        :filter="ordersTable.filter"
        :filter-included-fields="ordersTable.filterOn"
        @row-clicked="rowClicked"
      >
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- pagination -->
        <div>
          <b-pagination
            v-model="ordersTable.currentPage"
            :total-rows="ordersTable.totalRows"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @change="handlePageChange"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      size="xl"
      ref="show-order-details-modal"
      title="Order Details"
      cancel-title="Close"
      cancel-variant="outline-secondary"
      :no-close-on-backdrop="true"
    >
      <b-row>
        <b-col
          xl="4"
          md="4"
        >
        <b-card>
          <dl class="row">
            <dt class="col-sm-4">
              Order number
            </dt>
            <dd class="col-sm-8">
              {{orderNo}}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Hub
            </dt>
            <dd class="col-sm-8">
              {{hubName}}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Customer
            </dt>
            <dd class="col-sm-8">
              {{customerName}}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Total amount
            </dt>
            <dd class="col-sm-8">
              {{totalAmount}}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Total Amount Excl. Tax
            </dt>
            <dd class="col-sm-8">
              {{totalAmountExclt}}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Shipping Cost
            </dt>
            <dd class="col-sm-8">
              {{shippingCost}}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Order cycle id
            </dt>
            <dd class="col-sm-8">
              {{orderCycleID}}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Completed at
            </dt>
            <dd class="col-sm-8">
              {{completedAt}}
            </dd>
          </dl>
          <b-button
            block
            variant="primary"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-if="xmlGeneratedAt"
            :disabled="disabledButton"
            @click="regeneratOrderXML"
          >
            <b-spinner small
              v-if="disabledButton"
            />
            Regenerate Order XML
          </b-button>
        </b-card>
      </b-col>
        <b-col
          xl="8"
          md="8"
        >
          <b-card title="Items">
            <b-table
              striped
              hover
              responsive
              class="position-relative"
              :current-page="lineItemsTable.currentPage"
              :items="lineItemsTable.items"
              :fields="lineItemsTable.fields"
              :sort-by.sync="lineItemsTable.sortBy"
              :sort-desc.sync="lineItemsTable.sortDesc"
              :sort-direction="lineItemsTable.sortDirection"
              :filter="lineItemsTable.filter"
              :filter-included-fields="lineItemsTable.filterOn"
            >
            </b-table>

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="lineItemsTable.currentPage"
                  :total-rows="lineItemsTable.totalRows"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BButton,
  BCardBody,
  BCard,
  BFormGroup,
  BFormInput,
  BSpinner,
} from "bootstrap-vue"
import vSelect from "vue-select"
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from "vue-ripple-directive"
import axios from "axios"

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BButton,
    BCardBody,
    BCard,
    BFormGroup,
    BFormInput,
    BSpinner,
    BCardActions,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      hubs: [],
      selectedHubs: [],
      searchInputValue: "",
      queryParams: {},
      ordersTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: "id",
        sortDesc: true,
        sortDirection: "desc",
        filter: null,
        filterOn: [],
        infoModal: {
          id: "info-modal",
          title: '',
          content: '',
        },
        fields: [
          { key: "id", label: "id", sortable: true },
          { key: "number", label: "Order number", sortable: true },
          { key: "hub_name", label: "Hub", sortable: true },
          { key: "customer_name", label: "Customer name", sortable: true },
          { key: "customer_email", label: "customer email", sortable: true },
          { key: "total_amount", label: "Total amount", sortable: true },
          { key: "order_cycle_id", label: "Order cycle id", sortable: true },
          {
            key: "completed_at",
            label: "Completed at",
            sortable: true,
            formatter: value => {
              let new_date = new Date(value)
              if (value) {
                return new_date.toLocaleDateString("de-DE")
              }
              else {
                return ""
              }
            }
          },
          {
            key: "xml_generated_at",
            label: "XML generated at",
            sortable: true,
            formatter: value => {
              let new_date = new Date(value)
              if (value) {
                return new_date.toLocaleString("de-DE", {
                  timeZone: "Europe/Berlin"
                })
              }
              else {
                return ""
              }
            }
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      orderNo: "",
      hubName: "",
      customerName: "",
      totalAmount: "",
      totalAmountExclt: "",
      shippingCost: "",
      orderCycleID: "",
      completedAt: "",
      xmlGeneratedAt: null,
      disabledButton: false,
      lineItemsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: "price",
        sortDesc: true,
        sortDirection: "desc",
        filter: null,
        filterOn: [],
        infoModal: {
          id: "info-modal",
          title: '',
          content: '',
        },
        fields: [
          { key: "product_name", label: "product name", sortable: true },
          { key: "name_to_display", label: "name to display", sortable: true },
          { key: "unit_to_display", label: "unit to display", sortable: true },
          { key: "sku", label: "sku", sortable: true },
          { key: "quantity", label: "quantity", sortable: true },
          { key: "price", label: "price", sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  methods: {
    getMyHubs() {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/hubs/all/my/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          },
        })
        .then(response => {
          const myHubs = response.data.results.map( item => { return item.name})
          this.hubs = myHubs
          this.selectedHubs = myHubs
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    getOrders() {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/orders/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          },
          params: this.queryParams,
        })
        .then(response => {
          this.ordersTable.items = response.data.results
          this.ordersTable.totalRows = response.data.count * 2
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    getOrderDetails(orderID) {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/orders/" + orderID + "/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          },
        })
        .then((response) => {
          this.orderNo = response.data.number
          this.hubName = response.data.hub_name
          this.customerName = response.data.customer_name
          this.totalAmount = response.data.total_amount
          this.totalAmountExclt = response.data.total_amount_exclt
          this.shippingCost = response.data.shipping_cost
          this.orderCycleID = response.data.order_cycle_id
          this.completedAt = response.data.completed_at
          this.xmlGeneratedAt = response.data.xml_generated_at
          this.lineItemsTable.items = response.data.line_items
          this.lineItemsTable.totalRows = response.data.count * 2
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    regeneratOrderXML() {
      this.disabledButton = true
      axios
        .get(process.env.VUE_APP_ROOT_API + "/regenerate-order-xml/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          },
          params: {order_no: this.orderNo},
        })
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    rowClicked(row) {
      this.$refs["show-order-details-modal"].show()
      this.getOrderDetails(row["id"])
    },
    handlePageChange(value) {
      this.queryParams["limit"] = 10
      this.queryParams["offset"] = (value-1) * 10
      this.getOrders()
    },
    changeSelectedHubs() {
      this.queryParams["selected_hubs"] = this.selectedHubs
      this.getOrders()
    },
    searchOrder() {
      this.queryParams["search"] = this.searchInputValue
      this.getOrders()
    },
  },
  created() {
    this.getMyHubs()
    this.getOrders()
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
